import React, { FC } from 'react';

interface ISVGIcon {
  width: string;
  height: string;
  onClick?: () => void;
}

const GithubIcon: FC<ISVGIcon> = ({ width, height, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 31.209"
      onClick={onClick}
    >
      <path
        id="github"
        d="M16,.4a16,16,0,0,0-5.058,31.182c.8.148,1.094-.347,1.094-.77,0-.381-.015-1.642-.022-2.979C7.562,28.8,6.623,25.94,6.623,25.94A4.24,4.24,0,0,0,4.847,23.6c-1.452-.993.11-.973.11-.973a3.363,3.363,0,0,1,2.452,1.649,3.406,3.406,0,0,0,4.656,1.33,3.405,3.405,0,0,1,1.016-2.14c-3.554-.4-7.29-1.777-7.29-7.907a6.192,6.192,0,0,1,1.649-4.3A5.748,5.748,0,0,1,7.6,7.029S8.939,6.6,12,8.669a15.16,15.16,0,0,1,8.013,0c3.054-2.07,4.4-1.64,4.4-1.64a5.742,5.742,0,0,1,.157,4.234,6.18,6.18,0,0,1,1.647,4.3c0,6.145-3.743,7.5-7.306,7.9a3.825,3.825,0,0,1,1.085,2.963c0,2.141-.019,3.864-.019,4.391,0,.426.288.925,1.1.768A16,16,0,0,0,16,.395Z"
        transform="translate(0 -0.395)"
        fill="#444"
      />
    </svg>
  );
};

export default GithubIcon;
